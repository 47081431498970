import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSection from '../containers/Banner';
import Navbar from '../containers/Navbar';
import WorkingProcessSection from '../containers/WorkingProcess';
import TextBoxSection from '../containers/TextBox';
import PricingSection from '../containers/Pricing';
import PartnerSection from '../containers/Partner';
import InfoSection from '../containers/Info';
import UpdateScreen from '../containers/UpdateScreen';
// import TestimonialSection from '../containers/Testimonial';
import Footer from '../containers/Footer';
import NewsletterSection from '../containers/NewsletterSection';
import SEO from '../components/seo';
import FaqSection from '../containers/Faq';
import { useTranslation } from 'react-i18next';

const Index = ({ title, description, pageContext }) => {
  const { t } = useTranslation();

  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PROCESS_ITEMS {
          title
          alt
          description
          image {
            publicURL
          }
        }
        BUSINESS_PRICING_TABLE {
          id
          hostingType
          name
          titleImage {
            publicURL
          }
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
            link
          }
        }
        INDEX_FAQ_DATA {
          title
          description
          expend
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t(
            'Psono - Self Hosted and Open Source Password Manager for Companies'
          )}
          lang={pageContext.lang}
          titleTemplate={`%s`}
          description={t(
            'Free open-source password manager for businesses with SAML, LDAP, audit logs, and compliance policy features. Supports Windows, Linux, Mac.'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            pageContext={pageContext}
            topText={t('All business features free up to 10 users')}
            titleText={t(
              'Open Source Self Hosted Password Manager for Companies'
            )}
            descriptionText={t('Secure self-hosted solution for businesses')}
            primeLink={'https://www.psono.pw/'}
            primeText={t('ACCESS PSONO FREE')}
            downloadLink={`${pageContext.langPathPrefix}/download`}
            downloadText={t('DOWNLOAD')}
          />
          <TextBoxSection
            description={t('What is Psono and how can it help?')}
            text={t(
              'Psono is an open source and self-hosted password manager to help keep your data safe. It stores your credentials encrypted and only you can access your data. Access can be shared encrypted with your team. As an open source password manager, Psono comes with a variety of features to manage your data and access your passwords more easily than ever before.'
            )}
          />
          <WorkingProcessSection
            title={t('Key Features')}
            description={t(
              'What makes this self hosted password manager secure?'
            )}
            buttonUrl="/features-for-users"
            items={Data.saasModernJson.PROCESS_ITEMS}
            pageContext={pageContext}
          />
          <InfoSection pageContext={pageContext} />
          <PricingSection
            sectionInfo={Data.saasModernJson.BUSINESS_PRICING_TABLE}
          />
          <NewsletterSection />
          {/*<TestimonialSection />*/}
          <UpdateScreen />
          <PartnerSection />
          <FaqSection data={Data.saasModernJson.INDEX_FAQ_DATA} />
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

Index.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
};

Index.defaultProps = {
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
};

export default Index;

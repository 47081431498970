import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { useTranslation, Trans } from 'react-i18next';

const NewsletterSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  const { t } = useTranslation();
  return (
    <Box {...sectionWrapper} id="newsletter_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={t('NEWSLETTER SIGNUP')} />
          <Heading
            {...secHeading}
            content={t('Want to stay up to date with the latest news?')}
          />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <div style={{ padding: '8px 0' }}>
              <div
                style={{
                  display: 'flex',
                  padding: '0 16px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    padding: '0 16px 0 0',
                    margin: '0',
                  }}
                >
                  <svg
                    className="icon__SVG"
                    width={0}
                    height={0}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <symbol id="svgIcon-sphere" viewBox="0 0 63 63">
                        <path
                          className="path1"
                          d="M31.54 0l1.05 3.06 3.385-.01-2.735 1.897 1.05 3.042-2.748-1.886-2.738 1.886 1.044-3.05-2.745-1.897h3.393zm13.97 3.019L46.555 6.4l3.384.01-2.743 2.101 1.048 3.387-2.752-2.1-2.752 2.1 1.054-3.382-2.745-2.105h3.385zm9.998 10.056l1.039 3.382h3.38l-2.751 2.1 1.05 3.382-2.744-2.091-2.743 2.091 1.054-3.381-2.754-2.1h3.385zM58.58 27.1l1.04 3.372h3.379l-2.752 2.096 1.05 3.387-2.744-2.091-2.75 2.092 1.054-3.387-2.747-2.097h3.376zm-3.076 14.02l1.044 3.364h3.385l-2.743 2.09 1.05 3.392-2.744-2.097-2.743 2.097 1.052-3.377-2.752-2.117 3.385-.01zm-9.985 9.91l1.045 3.364h3.393l-2.752 2.09 1.05 3.393-2.745-2.097-2.743 2.097 1.05-3.383-2.751-2.1 3.384-.01zM31.45 55.01l1.044 3.043 3.393-.008-2.752 1.9L34.19 63l-2.744-1.895-2.748 1.891 1.054-3.05-2.743-1.9h3.384zm-13.934-3.98l1.036 3.364h3.402l-2.752 2.09 1.053 3.393-2.747-2.097-2.752 2.097 1.053-3.382-2.743-2.1 3.384-.01zm-9.981-9.91l1.045 3.364h3.398l-2.748 2.09 1.05 3.392-2.753-2.1-2.752 2.096 1.053-3.382-2.743-2.102 3.384-.009zM4.466 27.1l1.038 3.372H8.88l-2.752 2.097 1.053 3.387-2.743-2.09-2.748 2.09 1.053-3.387L0 30.472h3.385zm3.069-14.025l1.045 3.382h3.395L9.23 18.56l1.05 3.381-2.752-2.09-2.752 2.09 1.053-3.381-2.744-2.1h3.384zm9.99-10.056L18.57 6.4l3.393.01-2.743 2.1 1.05 3.373-2.754-2.092-2.751 2.092 1.053-3.382-2.744-2.1h3.384zm24.938 19.394l-10-4.22a2.48 2.48 0 00-1.921 0l-10 4.22A2.529 2.529 0 0019 24.75c0 10.47 5.964 17.705 11.537 20.057a2.48 2.48 0 001.921 0C36.921 42.924 44 36.421 44 24.75a2.532 2.532 0 00-1.537-2.336zm-2.46 6.023l-9.583 9.705a.83.83 0 01-1.177 0l-5.416-5.485a.855.855 0 010-1.192l1.177-1.192a.83.83 0 011.177 0l3.65 3.697 7.819-7.916a.83.83 0 011.177 0l1.177 1.191a.843.843 0 010 1.192z"
                          fill="#0092FF"
                        />
                      </symbol>
                    </defs>
                  </svg>
                  <svg
                    className="svgIcon-sphere"
                    style={{ width: '63px', height: '63px' }}
                  >
                    <use xlinkHref="#svgIcon-sphere" />
                  </svg>
                </div>
                <p
                  style={{
                    fontSize: ['15px', '15px', '15px', '15px', '15px'],
                    color: '#343d48cc',
                    lineHeight: '2',
                    mb: '33px',
                  }}
                >
                  <Trans i18nKey="newsletter_signup_message">
                    We send updates on a regular basis using Sendinblue as our
                    marketing platform. By clicking below, you signup to Psono’s
                    newsletter, and you acknowledge that the information you
                    provide will be transferred to Sendinblue for processing in
                    accordance with their{' '}
                    <a
                      target="_blank"
                      rel="noreferrer nofollow noopener"
                      className="clickable_link"
                      href="https://www.brevo.com/legal/termsofuse/"
                    >
                      terms of use
                    </a>
                  </Trans>
                </p>
              </div>
            </div>
            <Box {...buttonWrapper}>
              <a
                target="_blank"
                rel="noreferrer"
                className="clickable_link"
                href="https://8244f497.sibforms.com/serve/MUIEAHSugjn1kThxGI0zQEYIWlJIc1etOdAh664soWMhVyMRDHOaNrpo5WEV7V8_11SmVKD6oVv7T_4rk_6h55Sr82hKjVxP5fK-3ggBMXAylFvf6ukw3TNAtOSdLrqTe-RgF_zEiA7XT3omsmv7rkfd2XNba8hs-Wm99oMbWL1cPzEP0hDS1goRmVty-41SJB5xl0nAawm4PJR9"
              >
                <Button {...button} title={t('SUBSCRIBE')} />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

NewsletterSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

NewsletterSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default NewsletterSection;
